@use '../../partials/variables' as *;
@use '../../partials/mixins' as *;

/*------------------------------------*\
  #TAB-NAV
\*------------------------------------*/

.tab-nav {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5rem;

    @include large-mobile {
        flex-direction: row;
        border-bottom: 1px solid rgba($color-site-theme-2, 0.15);
    }
    

    &__header {
        font-family: $header-font;
    }

    &__link {
        display: block;
        padding: 1rem;
        border-bottom: 4px solid transparent;
        color: rgba($color-site-theme-2, 0.45);
        text-decoration: none;
        transition: color 200ms ease;

        @include large-mobile {
            padding: 1rem 1.5rem 1rem;
        }

        &:focus {
            outline: none;
        }

        &:hover {
            color: $color-site-theme-2;
        }

        &--active {
            order: 3;
            box-sizing: border-box;
            border-bottom: 3px solid $color-site-theme-2;
            background-color: rgba($color-site-theme-3, 0.15);
            color: $color-site-theme-2;
            transition: background-color 200ms ease;

            @include large-mobile {
                order: unset;
            }
            
            &:focus {
                outline: none;
            }
        }
    }
}
// ===========================\\
//  #Media query mixins
//============================\\

@mixin small-mobile {
  @media screen and (min-width: 375px) {
    @content;
  }
}

@mixin large-mobile {
  @media screen and (min-width: 600px) {
    @content;
  }
}

@mixin tablet {
  @media screen and (min-width: 768px) {
    @content;
  }
}

@mixin large-tablet {
  @media screen and (min-width: 1024px) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: 1130px) {
    @content;
  }
}

@mixin fade-in {
  animation: 2200ms ease fade-in;

  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}

$color-site-theme-1: #666;
$color-site-theme-2: #333;
$color-site-theme-3: #adb09b;
$color-moody: #009a82;

$davys-grey: #59514aff;
$bright-yellow-crayola: #f6aa1cff;
$lavender-blue: #c6d4ffff;
$maximum-blue-green: #2dc2bdff;
$terra-cotta: #e26d5aff;

$body-font: "Nunito Sans", "Helvetica", "sans serif";
$header-font: "Roboto", "Helvetica", "sans serif";

$content-max-width: 63.25rem;

/*

{   
    "Silver Metallic":"ada8b6",
    "Antique White":"ffeedb",
    "English Violet":"4c3b4d",
    "Maroon X 11":"a53860",
    "Ocean Green":"61c9a8"
}

*/

@use "./partials/typography";
@use "./partials/variables" as *;
@use "./partials/mixins" as *;

html {
  scroll-behavior: smooth;
  font-size: 16px;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  padding-top: 3rem;
  color: #333333;
  font-family: $body-font;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 1rem;
}

.main {
  max-width: $content-max-width;
  padding: 0 2rem 3rem;
  margin: 0 auto;
}

.article {
  &__copy {
    padding: 2rem 0;
    max-width: 100%;

    @include large-mobile {
      max-width: 85%;
    }

    @include tablet {
      padding: 2rem 1rem;
      max-width: 75%;
    }
  }
}

.spacer {
  height: 2rem; 
}

.image {
  width: 100%;
  max-width: 100%;
}

.heading1 {
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  font-family: $header-font;
  color: $color-site-theme-1;
}

.heading2 {
  margin-bottom: 0.5rem;
  font-size: 3rem;
  font-weight: 100;
  font-family: $header-font;
  color: $color-site-theme-2;

  @include small-mobile {
    font-size: 5rem;
  }
}

.bold {
  font-weight: 600;
}

.ital {
  font-style: italic;
}

.text-block {
  display: inline-block;
}

.intro-animation {
  width: fit-content;
}

.banner {
  width: 100%;
  aspect-ratio: 9 / 6;
}

.site-title {
  font-weight: 300;
  color: $color-site-theme-1;
}

.paragraph {
  margin-bottom: 2rem;
  font-size: 1rem;
  line-height: 1.5rem;
  color: $color-site-theme-2;

  @include tablet {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  &--alt {
    @include tablet {
      padding: 0 1.5rem 0;
    }
  }
}

.flex-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 2rem 0 0;

  &--start {
    justify-content: flex-start;
    align-items: flex-start;
  }

  &--between {
    justify-content: space-between;
  align-items: flex-start;
  }
}

.credits {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @include tablet {
    flex-direction: row;
    justify-content: space-between;
  }

  &__header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    margin-bottom: 1.5rem;
  }

  &__column {
    flex: 1;
    margin: 1rem 0 2rem 0;

    @include tablet {
      margin: 1rem 2rem 2rem 0;
    }

    &:nth-last-of-type(1) {
      margin: 1rem 0 2rem 0;

      @include tablet {
        margin: 1rem 0 2rem 2rem;
      }
    }
  }

  &__list {
    margin-bottom: 1rem;
  }
}

.credit {
  padding-bottom: 0.5rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  margin-bottom: 0.5rem;

  &__head {
    display: flex;
    justify-content: space-between;
  }

  &__sub {
    display: flex;
    justify-content: space-between;
  }

  &__title {
    text-transform: uppercase;
    font-weight: 900;
  }

  &__year {
    font-weight: 900;
  }

  &__format {
    font-weight: 300;
    text-transform: capitalize;
  }

  &__dir {
    text-align: right;
    margin-left: 0.25;
  }
}

.gallery {
  min-height: 70vh;

  &__container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 2rem;

    @include large-mobile {
      justify-content: flex-start;
    }
  }

  &__thumb {
    width: 100%;
    margin-right: 0;
    overflow: hidden;
    cursor: pointer;

    @include large-mobile {
      width: calc(100% / 2 - 0.4rem / 2);
      margin-right: 0.4rem;

      &:nth-of-type(2n + 2) {
        margin-right: 0;
      }
    }

    @include tablet {
      width: calc(100% / 3 - 0.4rem / 1.5);
      margin-right: 0.4rem;

      &:nth-of-type(2n + 2) {
        margin-right: 0.4rem;
      }

      &:nth-of-type(3n + 3) {
        margin-right: 0;
      }
    }
  }
}

.overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  justify-content: center;
  align-items: center;
  width: 100vw;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);

  &--active {
    display: flex;
  }

  &__image {
    max-width: 720px;
    cursor: pointer;
  }
}

.link {
  
  color: $color-site-theme-2;
  transition: all 150ms ease;

  &:hover {
    color: rgba($color-site-theme-2, 0.75);
  }

  &__icon {
    width: 100%;
    max-width: 0.85rem;
    margin-right: 0.25rem;
    fill: $color-site-theme-1;
  }

  &--contact {
    color: white;
  }

  &--causes {
    color: white;
  }

  &--image {
    width: 100%;
    max-width: 10rem;
  }

  &--footer {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 1rem;
    display: block;
    width: fit-content;
    margin: 0 auto;
    font-size: 0.75rem;
    color: white;
  }
}

.header {
  max-width: $content-max-width;
  padding: 0 2rem 2rem;
  margin: 0 auto;

  &__subtitle {
    width: fit-content;
    padding: 0.25rem 0.5rem;
    margin: 0.75rem 0 1rem;
    background-color: rgba($color-site-theme-3, 0.15);
    font-weight: 100;
  }
}

.head-shot {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  margin: 1rem 0 1.25rem;
  overflow: hidden;
}

.demo-reel {
  &__link {
    display: block;
    margin-top: 1rem;
  }
}

.laptop {
  position: absolute;
  right: 2rem;
  bottom: 2rem;
  transform: rotate(-05deg);

  &__svg {
    position: relative;
    z-index: 10;
    fill: $color-site-theme-2;
  }

  &__circle {
    position: absolute;
    height: 100px;
    width: 100px;
    background-color: $color-site-theme-1;
    border-radius: 50%;
    transform: translate(-1rem, -1rem);
  }
}

.work {
  width: 100%;

  &__list {
    list-style: none;
  }
}

.format {
  padding: 1rem 0.25rem 1.5rem;
  font-size: 1.25rem;
  line-height: 2rem;

  &__feature {
    display: block;
    width: fit-content;
    padding: 0.25rem 0.5rem 0.35rem;
    margin-right: 0.75rem;
    background-color: rgba($color-site-theme-3, 0.15);
    font-family: $header-font, serif;
    font-size: 1.15em;
    // animation-name: fade-in;

    // @include fade-in

    @include large-mobile {
      display: inline;
    }
  }
}

.project {
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  &:first-of-type {
    border-top: none;
  }

  &:last-of-type {
    border-bottom: none;
  }

  @include large-tablet {
    flex-direction: row;
    align-items: flex-end;
  }

  &--alt {
    @include large-tablet {
      flex-direction: column;
      align-items: stretch;
    }
  }

  &__header {
    flex-basis: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 1rem;

    &--alt {
      flex-direction: column;
    }

    @include large-tablet {
      align-items: flex-end;
    }
  }

  &__title {
    font-family: $header-font;
    font-size: 1.75rem;
  }

  &__image {
    width: 100%;
    max-width: 6rem;
    border: 1px solid $color-site-theme-1;

    @include large-tablet {
      max-width: 10rem;
    }

    &--alt {
      max-width: 100%;
      border: none;

      @include large-tablet {
        max-width: 100%;
      }
    }
  }

  &__icon {
    width: 100%;
    max-width: 12rem;

    &--moody {
      fill: $color-moody;
    }

    &--tech {
      width: 100%;
      max-width: 3.5rem;
    }

    &--tech-large {
      width: 100%;
      max-width: 2.5rem;
    }
  }

  &__overview {
    padding: 0.5rem 0 0 1rem;
    list-style: none;

    @include large-tablet {
      padding: 1rem 1.5rem 0;
    }

    &--alt {
      padding: 0;
      margin-bottom: 1rem;

      @include tablet {
        padding: 0 1.5rem 0;
      }
    }
  }

  &__component {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 0.35rem 0;

    &--type {
      font-size: 0.75rem;
      font-weight: 400;
      text-transform: uppercase;
    }

    &--link {
      font-size: 0.75rem;
    }
  }

  &__content {
    @include large-tablet {
      flex-basis: 50%;
    }
  }

  &__description {
    margin-bottom: 0.75rem;
  }

  &__tech-stack {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    padding: 0.75rem;
    margin: 0 0 1rem;
    background-color: rgba(0, 0, 0, 0.025);
    list-style: none;
  }

  &__tech {
    display: flex;
    align-items: center;
    margin: 0.75rem;
  }

  &__links {
    list-style: none;
    font-size: 0.85rem;
  }

  &__link {
    margin-bottom: 0.25rem;
  }
}

#html {
  width: 1.85rem;
}

.footer {
  position: relative;
  padding: 4rem 2rem 6rem;
  background-color: $color-site-theme-2;

  &__content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: $content-max-width;
    margin: 0 auto;
  }

  &__label {
    color: white;
    margin-bottom: 0.5rem;
  }
}

.contact {
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 2rem;
  margin-bottom: 2rem;
  background-color: rgba(white, 0.025);

  &__list {
    list-style: none;
    line-height: 1.5rem;
  }

  &__icon {
    display: inline-block;
    width: 1rem;
    height: 0.75rem;
    margin-right: 0.5rem;
    fill: white;
  }

  @include tablet {
    flex-basis: 48%;
  }
}

.causes {
  @extend .contact;

  &__list {
    list-style: none;
    font-weight: 400;
    line-height: 1.45rem;
  }
}

.iframe-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  height: 0;
}

.iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
